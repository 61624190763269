<template>
<div>
    <!-- end row -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-8">
                            <form class="form-inline">
                                <div class="form-group">
                                    <label class="sr-only">Search</label>
                                    <input v-model="searchQuery" type="search" class="form-control" placeholder="Search..." />
                                </div>
                                <div class="form-group mx-sm-3">
                                    <select id="status-select" v-model="sordBy"  class="custom-select">
                                        <option disabled selected>-Sort By-</option>
                                        <option value='real'>Original</option>
                                        <option value='created'>Created</option>
                                        <option value='all'>Show all</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-4">
                            <div class="text-lg-right mt-3 mt-lg-0">
                                <a class="btn text-white btn-primary" href="javascript: void(0);" @click="showCreateModal = true">
                                    <i class="mdi mdi-plus-circle mr-1"></i> Create Review
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="reviews.length">
        <div class="row">
            <div class="col-md-6" v-for="(review, index) in paginatedReviews" :key="index">
                <div class="card">
                    <div class="card-body">
                        <div class="media" v-if="review.is_real">
                            <div class="mr-3">
                                <img :src="absoluteUrl(review.reviewer.image)" alt="user-image" class="rounded avatar-md" />
                            </div>
                            <div class="media-body">
                                <h5 class="mb-1 mt-0">
                                    {{review.reviewer.name}}
                                </h5>
                                <p class="text-muted mb-1">
                                    <i class="mdi mdi-flag-checkered mr-1"></i>
                                    <span v-if="review.reviewer.country">{{review.reviewer.country.name}} </span>
                                </p>
                                <p class="text-muted">
                                    <i class="mdi mdi-star text-warning" v-for="rate in review.rating" :key="rate"></i>
                                </p>
                            </div>
                             <b-dropdown right toggle-class="text-body p-0" variant="black">
                                <template v-slot:button-content>
                                    <i class="mdi mdi-dots-vertical font-20"></i>
                                </template>
                                <b-dropdown-item @click.prevent="onUpdate(review)">Edit</b-dropdown-item>
                                <b-dropdown-item @click.prevent="deleteItem(review.id)" >Delete</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="media" v-else>
                             <div class="mr-3" v-if="review.reviewer_image">
                                <img :src="absoluteUrl(review.reviewer_image)" alt="user-image" class="rounded avatar-md" />
                            </div>
                            <div class="avatar-md mr-3" v-else>
                                <div class="avatar-title bg-light rounded text-body font-20 font-weight-semibold">{{ review.reviewer_name.charAt(0) }}</div>
                            </div>
                            <div class="media-body">
                                <h5 class="mb-1 mt-0">
                                    {{review.reviewer_name}}
                                </h5>
                                <p class="text-muted mb-1">
                                    <i class="mdi mdi-flag-checkered mr-1"></i>
                                    <span v-if="review.country">{{review.country.name}} </span>
                                </p>
                                <p class="text-muted">
                                    <i class="mdi mdi-star text-warning" v-for="rate in review.rating" :key="rate"></i>
                                </p>
                            </div>
                             <b-dropdown right toggle-class="text-body p-0" variant="black">
                                <template v-slot:button-content>
                                    <i class="mdi mdi-dots-vertical font-20"></i>
                                </template>
                                <b-dropdown-item @click.prevent="onUpdate(review)">Edit</b-dropdown-item>
                                <b-dropdown-item @click.prevent="deleteItem(review.id)" >Delete</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div class="">
                            <p class="mb-0 font-14">
                                {{review.content}} <router-link v-if="review.is_real" :to="'/orders/'+review.order_id">View Order</router-link>
                            </p>
                        </div>
                        <hr class="my-2"/>
                        <div class="text-muted">
                            <div class="row">
                                <div class="col-6">
                                    <div>
                                        <p class="text-truncate mb-1 font-14">Product</p>
                                        <h5 class="my-0 font-14" v-if="review.product">
                                            <router-link :to="'/products/'+review.product.id">{{ review.product.name }}</router-link>
                                        </h5>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="text-right">
                                        <p class="text-truncate mb-1 font-14">Reviewed Date</p>
                                        <h5 class="my-0 font-14"><span class="badge badge-success"> {{ review.is_real ?  review.created_at : review.reviewed_at | date_parse(true)}}</span></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <div class="text-md-right mt-2 pagination-rounded">
                    <b-pagination v-model="currentPage" :total-rows="reviews.length" :per-page="perPage" @input="onPageChange"></b-pagination>
                </div>
            </div>
        </div>
    </div>
    <b-modal centered id="modal-1" v-model="showCreateModal" title="Create Review" header-close-variant="primary" title-class="font-18" hide-footer>
        <create-review-form ref="createReviewComponent" @resetForm="initialize()"/>
    </b-modal>
</div>
</template>

<script>
import CreateReviewForm from "@/components/forms/CreateReviewForm.vue"

export default {
    components: {
        CreateReviewForm,
    },
    data() {
        return {
            showCreateModal:false,
            sordBy: 'all',
            currentPage: 1,
            // default page size
            perPage: 6,
            // start and end index
            startIndex: 1,
            endIndex: 15
        };
    },
    computed: {
        reviews() {
            return this.$store.getters['reviewList/reviews'](this.sordBy);
        },
        paginatedReviews(){
            return this.reviews.slice(
                this.startIndex,
                this.endIndex
            );
        },
        searchQuery: {
            get() {
                return this.$store.state.reviewList.reviewSearchQuery
            },
            set(val) {
                this.$store.commit('reviewList/SET_REVIEW_SEARCH_QUERY', val)
            }
        },
    },
    methods: {
        onUpdate(review){
            this.showCreateModal = true;
                setTimeout(()=>{
                  this.$refs.createReviewComponent.setUpdateMode(review)
            },200);
        },
        deleteItem(itemId){
            Swal.fire({
                title: "Are you sure?",
                text: "You are about to delete the selected item!",
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#e11",
                cancelButtonColor: "#111",
                confirmButtonText: "Delete it!",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("changeLoaderValue",true)
                    this.$http.delete(`/reviews/${itemId}/delete`)
                    .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit(`reviewList/DELETE_REVIEW`, itemId)
                        }
                    })
                }
            });
        },
        initialize(){
            this.showCreateModal = false
        },
        onPageChange() {
            this.startIndex = (this.currentPage - 1) * this.perPage;
            this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
        },
    },
    created() {
        this.startIndex = 0;
        this.endIndex = this.perPage;
        
        this.$store.dispatch("reviewList/fetchReviews")
    }
};
</script>


